<template>
  <div id="main-wrapper">
    <div class="section no-padding">
      <div class="banner-holder home-banner">
        <div class="video-bg">
          <video
            id="bannervid"
            autoplay
            muted
            loop
            poster="assets/images/video-poster.webp"
          >
            <source src="assets/video/map.mp4" type="video/mp4" />
            <source src="assets/video/map.ogg" type="video/ogg" />
            <source src="assets/video/map.webm" type="video/webm" />
          </video>
        </div>
        <div class="coverup"></div>
        <div class="wrapper2">
          <div class="content-holder middle-div">
            <h3>The Future of FOREX is...</h3>
            <h1>ULTRA-LOW commissions!</h1>

            <div class="banner-winner">
                            <div>
                                <ul>
                                    <li><strong>Raw spreads</strong> from <strong>0.0</strong> pips</li>
                                    <li>
                                      <b-button v-b-tooltip.hover title="professional customers">
                                        Leverage up to <strong>500x* <sup>professional customers</sup></strong> 
                                      </b-button>
                                    </li>
                                    <li><b>Greek</b> and EU Regulations and Registrations</li>
                                    <li>
                                      <b-button v-b-tooltip.hover title="professional clients">
                                        <strong>Free</strong> Deposits and Withdrawals*
                                      </b-button>
                                    </li>
                                </ul>
                            </div>
                            <div class="win-image">
                                <img class="lozad fluid-img" width="120" height="120" src="assets/images/winner-new.webp" alt="AAAFx" title="UFAWARDS" />
                            </div>

                        </div>
            <div class="banner-btn btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
            <br />
            <!-- <img src="assets/images/helenic.webp" alt=""> -->
          </div>
          <div class="home-banner content-holder">
            <img class="sasd fluid-img" src="assets/images/helenic.png" alt="AAAFx" title="AAAFx" width="351" height="50" />
          </div>
        </div>
      </div>
    </div>
    <div class="section fx-pairs">
      <div class="links-holder tabs-forex wow fadeIn">
        <ul class="clearfix">
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'forex',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'forex',
              },
            ]"
          >
            <a href="#forex" @click.stop.prevent="showTab = 'forex'"> Forex</a>
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'indices',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'indices',
              },
            ]"
          >
            <a href="#indices" @click.stop.prevent="showTab = 'indices'">
              Indices</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'comm',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'comm',
              },
            ]"
          >
            <a href="#comm" @click.stop.prevent="showTab = 'comm'">
              Commodities</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'stocks',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'stocks',
              },
            ]"
          >
            <a href="#stocks" @click.stop.prevent="showTab = 'stocks'">
              Stocks</a
            >
          </li>
          <li
            :class="[
              {
                'ui-tab-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active':
                  showTab == 'crypto',
                'ui-tabs-tab ui-corner-top ui-state-default ui-tab':
                  showTab != 'crypto',
              },
            ]"
          >
            <a href="#crypto" @click.stop.prevent="showTab = 'crypto'">
              Cryptocurrencies</a
            >
          </li>
        </ul>

        <div id="forex" class="tab-holder" v-show="showTab == 'forex'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>70+ FX Pairs</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>1</h3>
              </div>
              <div class="content colu">
                <h5>World’s most liquid market</h5>
                <p>
                  Enjoy access to the world’s most liquid markets
                </p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>2</h3>
              </div>
              <div class="content colu">
                <h5>Trade micro lot sizes</h5>
                <p>Trade as per any strategy, micro lots up to 100 lots</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>3</h3>
              </div>
              <div class="content colu">
                <h5>Advanced charting indicators</h5>
                <p>Use our splendid trading platforms with advanced tools</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >START TRADING</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >TRY A DEMO</a
              >
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>Pair</th>
                <!-- <th>Leverage</th> -->
                <th>Bid</th>
                <th>Ask</th>
                <th class="">Spreads</th>
                <th>Trade</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_currencies"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!--  <td><span class="lev">1-30X</span></td> -->
                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td><router-link to="/web-trader" class="btn btn-blue">BUY/SELL</router-link></td>
              </tr>
            </table>
            <router-link to="/forex" class="t-right see-link">
              See All</router-link
            >
          </div>
        </div>
        <div id="indices" class="tab-holder" v-show="showTab == 'indices'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>11+ Indices</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>1</h3>
              </div>
              <div class="content colu">
                <h5>Trade ever growing Indices</h5>
                <p>such as DOW JONES, S&P 500, Nasdaq and many more…</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>2</h3>
              </div>
              <div class="content colu">
                <h5>Low Spreads and low margin requirements</h5>
                <p>
                  We have thinnest spreads, high leverage on all Global Indices
                </p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>3</h3>
              </div>
              <div class="content colu">
                <h5>Diversify- trade a portfolio, not a single stock</h5>
                <p>We offer all major Global Indices for trading</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >START TRADING</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >TRY A DEMO</a
              >
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>Pair</th>
                <!-- <th>Leverage</th> -->
                <th>Bid</th>
                <th>Ask</th>
                <th class="">Spreads</th>
                <th>Trade</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_indices"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->
                <!-- <td>
                  <span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus < 0 }]">{{spreads.B}}</span>
                </td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus < 0 }]">{{spreads.A}}</span></td>-->
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td><router-link to="/web-trader" class="btn btn-blue">BUY/SELL</router-link></td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/indices" class="t-right see-link">
              See All</router-link
            >
          </div>
        </div>
        <div id="comm" class="tab-holder" v-show="showTab == 'comm'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>9 Commodities</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>1</h3>
              </div>
              <div class="content colu">
                <h5>Broad range of commodities</h5>
                <p>All major precious metals, Oil and Natural Gas available</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>2</h3>
              </div>
              <div class="content colu">
                <h5>Invest less, get more</h5>
                <p>Get hold of precious assets easily with leverage</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>3</h3>
              </div>
              <div class="content colu">
                <h5>Zero latency, Minimal slippage</h5>
                <p>Minimal impact of volatility with execution speed 10 ms.</p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >START TRADING</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >TRY A DEMO</a
              >
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>Pair</th>
                <!-- <th>Leverage</th> -->
                <th>Bid</th>
                <th>Ask</th>
                <th>Spreads</th>
                <th>Trade</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Commodity"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" />  -->{{
                    spreads.other.mn
                  }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->

                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td><router-link to="/web-trader" class="btn btn-blue">BUY/SELL</router-link></td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/commodities" class="t-right see-link">
              See All</router-link
            >
          </div>
        </div>
        <div id="stocks" class="tab-holder" v-show="showTab == 'stocks'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>100+ Stocks</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>1</h3>
              </div>
              <div class="content colu">
                <h5>Trade Blue Chip Stocks</h5>
                <p>Drag trusted blue-chip stocks into your portfolio</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>2</h3>
              </div>
              <div class="content colu">
                <h5>Earn dividends without owning stocks</h5>
                <p>Earn exciting dividend income from top notch companies</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>3</h3>
              </div>
              <div class="content colu">
                <h5>Go long or Go short, as per Market</h5>
                <p>
                  Trade not only rising but falling stocks, using any trading
                  strategy
                </p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >START TRADING</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >TRY A DEMO</a
              >
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>Pair</th>
                <!-- <th>Leverage</th> -->
                <th>Bid</th>
                <th>Ask</th>
                <th class="">Spreads</th>
                <th>Trade</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Stocks"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" /> -->
                  {{ spreads.other.mn }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->

                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td>   -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td><router-link to="/web-trader" class="btn btn-blue">BUY/SELL</router-link></td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/stocks" class="t-right see-link">
              See All</router-link
            >
          </div>
        </div>
        <div id="crypto" class="tab-holder" v-show="showTab == 'crypto'">
          <div class="content-holder">
            <div class="title-holder wow fadeInLeft">
              <h3>6 Cryptocurrencies</h3>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".2s">
              <div class="number-holder colu">
                <h3>1</h3>
              </div>
              <div class="content colu">
                <h5>Trade Money spinning Cryptos</h5>
                <p>Trade the most lucrative assets of today’s age</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".4s">
              <div class="number-holder col">
                <h3>2</h3>
              </div>
              <div class="content colu">
                <h5>Most powerful cryptos right at your trading platform</h5>
                <p>All major cryptocurrencies available to trade</p>
              </div>
            </div>
            <div class="pair-holder wow fadeInLeft" data-wow-delay=".6s">
              <div class="number-holder col">
                <h3>3</h3>
              </div>
              <div class="content colu">
                <h5>Reliable trading for volatile assets</h5>
                <p>
                  Have access to deep liquidity with a Regulated, reliable
                  broker
                </p>
              </div>
            </div>
            <div class="btn-holder wow fadeInLeft" data-wow-delay=".7s">
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >START TRADING</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-lblue"
                >TRY A DEMO</a
              >
            </div>
          </div>
          <div class="table-holder wow fadeInRight home-table">
            <table>
              <tr>
                <th>Pair</th>
                <!-- <th>Leverage</th> -->
                <th>Bid</th>
                <th>Ask</th>
                <th>Spreads</th>
                <th>Trade</th>
              </tr>
              <tr
                v-for="(spreads, key, index) in get_Crypto"
                :key="key"
                :index="index"
                v-show="index < 4"
              >
                <td>
                  <!-- <img src="assets/images/bitcoin-ico.webp" alt="" /> -->
                  {{ spreads.other.mn }}
                </td>
                <!-- <td><span class="lev">1-30X</span></td> -->
                <!-- <td><span :class="[{'t-green':spreads.buy_increase_staus > 0 ,'t-red':spreads.buy_increase_staus > 0 }]">{{spreads.B}}</span></td>
                <td><span :class="[{'t-green':spreads.sell_increase_staus > 0 ,'t-red':spreads.sell_increase_staus > 0 }]">{{spreads.A}}</span></td> -->

                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.buy_increase_staus == 1,
                        't-red': spreads.buy_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.B }}</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      {
                        't-green': spreads.sell_increase_staus == 1,
                        't-red': spreads.sell_increase_staus == 0,
                      },
                    ]"
                    >{{ spreads.A }}</span
                  >
                </td>

                <td class="spread">{{ spreads.SPREAD }}</td>
                <td><router-link to="/web-trader" class="btn btn-blue">BUY/SELL</router-link></td>
              </tr>
              <!-- <tr>
                <td><img src="assets/images/bitcoin-ico.webp" alt="" /> BTC/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>65913.14</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/ada-ico.webp" alt="" /> ADA/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>2.07</td>
                <td><span class="t-green">+243%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/eth-ico.webp" alt="" /> ETH/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>4780</td>
                <td><span class="t-green">+3.97%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr>
              <tr>
                <td><img src="assets/images/solana-ico.webp" alt="" /> SOL/USDT</td>
                <td><span class="lev">1-30X</span></td>
                <td>248.193</td>
                <td><span class="t-green">+6.06%</span></td>
                <td class="spread">0</td>
                <td><a href="#" class="btn btn-blue">BUY/SELL</a></td>
              </tr> -->
            </table>
            <router-link to="/crypto" class="t-right see-link">
              See All</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="winnerLTC">
      <div class="wrapper2 t-center">
        <img src="assets/images/winner.webp" alt="Winner" title="UFAWARDS" />
      </div>
    </div> -->

    <div class="section why-join">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>Why You Should Join <strong>AAAFx</strong></h3>
        </div>
        <div
          class="join-container wow fadeIn"
          ontouchstart="this.classList.toggle('hover');"
        >
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico1.webp" alt="AAAFx" title="AAAFx" width="35" height="35" class="fluid-img" />
              </div>
              <div class="content">
                <h3>Top-notch Trading conditions</h3>
                <hr />
                <ul>
                  <li>Fastest trade execution with near zero spreads</li>
                  <li>Low commission and rollover charges</li>
                  <li>All trading strategies allowed</li>
                  <li>No hidden markups</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico2.webp" alt="AAAFx" title="AAAFx" width="35" height="35" class="fluid-img" />
              </div>
              <div class="content">
                <h3>Security and Reliability</h3>
                <hr />
                <ul>
                  <li>EU Regulated Broker.</li>
                  <li>Registration Number: 2/540/17.2.2010.</li>
                  <li>Negative Balance Protection.</li>
                  <li>Strict Adherence to Fiduciary Compliance.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico3.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="35" height="35" />
              </div>
              <div class="content">
                <h3>14 years’ Industry Experience</h3>
                <hr />
                <ul>
                  <li>In business from over a decade</li>
                  <li>Team of able and seasoned professionals</li>
                  <li>Trusted and Transparent Broker</li>
                  <li>Deep understanding of traders’ needs</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico4.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="35" height="35" />
              </div>
              <div class="content">
                <h3>Deep Liquidity Pool</h3>
                <hr />
                <ul>
                  <li>Institutional grade liquidity</li>
                  <li>No requotes and minimal slippage</li>
                  <li>Guaranteed liquidity at all price levels</li>
                  <li>Suitable for high volume trading</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico5.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="35" height="35" />
              </div>
              <div class="content">
                <h3>Technology</h3>
                <hr />
                <ul>
                  <li>High-end in-house technology</li>
                  <li>Servers in top-notch Equinix data centres</li>
                  <li>Fastest execution speed under 10 ms</li>
                  <li>Free collocated VPS providing even lower latency</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="join-holder">
            <div class="front">
              <div class="img-holder">
                <img src="assets/images/join-ico6.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="35" height="35" />
              </div>
              <div class="content">
                <h3>Ultra-Fast Withdrawals</h3>
                <hr />
                <ul>
                  <li>Free withdrawals within 24 hours</li>
                  <li>No restrictions on amount or frequency</li>
                  <li>Several flexible withdrawal options</li>
                  <li>High Security withdrawals processing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section zero-comm">
      <div class="wrapper2">
        <div class="content-holder img-cont wow fadeInLeft">
          <div class="title-holder">
            <h3>
              <strong>Raw Spreads, Zero Commission </strong><br />
              on FX pairs with an ECN plus account
            </h3>
          </div>
          <div class="content">
            <p>
              AAAFx is here to reduce the tension and commissions from your trading journey. Yes, you read that right! We have room for you to maneuver a low-cost trading experience with us. For more information <a href="javascript:void(0)">click here</a>
              <br /><br />
              We give you full potential to achieve the best possible result. With the use of
              state-of-the-art technology, we have empowered ourselves to get
              you instant access to deep liquidity pool at low-cost.
            </p>
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
        <div class="chart-container wow fadeInRight">
          <div class="chart-holder wow fadeIn" data-wow-delay="0.3s">
            <div class="title">
              <h3>ECN</h3>
            </div>
            <div class="chart-row">
              <h3>$5</h3>
              <p>Commission / $100K</p>
            </div>
            <div class="chart-row">
              <h3>0.0<span>pips</span></h3>
              <p>min. Spread</p>
            </div>
            <div class="chart-row">
              <h3>$100</h3>
              <p>min. Deposit</p>
            </div>
          </div>
          <div class="chart-holder wow fadeIn" data-wow-delay="0.6s">
            <div class="title">
              <h3>ECN <span>plus</span></h3>
            </div>
            <div class="chart-row">
              <h3>$3</h3>
              <p>Commission on Forex</p>
            </div>
            <div class="chart-row">
              <h3>0.0<span>pips</span></h3>
              <p>min. Spread</p>
            </div>
            <div class="chart-row">
              <h3>$10,000</h3>
              <p>min. Deposit</p>
            </div>
          </div>
          <a
            href="account-types"
            class="wow fadeIn"
            data-wow-delay="0.9s"
            target="_blank"
            >Compare Account Types</a
          >
        </div>
      </div>
    </div>
    <div class="section zero-comm dep-bonus">
      <div class="wrapper2">
        <div class="img-holder img-cont wow fadeInLeft">
          <img src="assets/images/trust-img.webp" alt="AAAFx" title="AAAFx" class="fluid-img" width="607" height="566" />
        </div>
        <div class="content-holder wow fadeInRight">
          <div class="title-holder">
            <h3>
              <strong
                >Experienced, Trusted and Regulated with a Promise of Protection
                of your funds
              </strong>
            </h3>
          </div>
          <div class="content">
            <p class="italic">Security is not a product, but a process.</p>
            <br />
            <p>
              With a strong value system, we deliver a world class trading
              experience. We comply with a strict Regulatory framework in order
              to protect your funds. We are regulated by the Hellenic Capital
              Market Commission (HCMC), that is governed by Greek and European
              legislations. <br /><br />
              Our clients rely on us as we take all measures to ensure safety of
              their funds and seamless execution of their trades.
              <br /><br />
              Trade with peace, trade for success!
            </p>
            <br />
            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section zero-comm zulutrade">
      <div class="wrapper2">
        <div class="content-holder wow fadeInLeft">
          <div class="title-holder">
            <h3>
              <strong>ZuluTrade</strong> - A Treasure House For Forex Traders!
            </h3>
          </div>
          <div class="content">
            <p class="italic">Copy a trading master or become one!</p>
            <br />
            <p>
              <span>Access the largest social trading network through a reliable
              broker, with our Zulu Account. It lets you automatically copy the
              trades of some successful traders who showcase their live trading
              performance.</span>
              <span title="Risk Disclosure"> 67%* </span> 
              <span>of their investors make profits when they copy top traders correctly. <br /><br /> Become a top trader or follow a top trader.</span>
            </p>

            <div class="btn-holder">
              <a :href="siteurl2" target="_blank" class="btn btn-yellow"
                >OPEN LIVE ACCOUNT</a
              >
              <a :href="siteurl2" target="_blank" class="btn btn-blue"
                >OPEN DEMO ACCOUNT</a
              >
            </div>
          </div>
        </div>
        <div class="img-holder img-cont wow fadeInRight">
          <img src="assets/images/zulu.webp" class="fluid-img" alt="AAAFx" title="AAAFx" width="620" height="521" />
          <img
            src="assets/images/zulu1.webp"
            alt="AAAFx"
            class="pop1 wow fadeInLeft fluid-img"
            data-wow-delay="0.6s" title="AAAFx" width="221" height="277"
          />
          <img
            src="assets/images/zulu2.webp"
            alt="AAAFx"
            class="pop2 wow fadeInRight fluid-img"
            data-wow-delay="0.6s" title="AAAFx" width="218" height="277"
          />
        </div>
      </div>
    </div>
    <div class="section trading-arsenal">
      <div class="wrapper2">
        <div class="title-holder t-white wow fadeIn">
          <h3>Your Trading <strong>Arsenal</strong></h3>
        </div>
        <div class="arsenal-cont wow fadeIn" data-wow-delay=".3s">
          <div class="arsenal-holder">
            <h3>Act Trader</h3>
            <p>Most Advanced Technology</p>
            <div class="img-holder">
              <img src="assets/images/act-img.webp" alt="AAAFx"
                title="ActTrader" width="407" height="221" class="fluid-img" />
            </div>
            <a v-if="noDataActTrader" href="javascript:void(0);">Coming Soon</a>
            <a
              v-if="
                static_vars.platformLinks.actTrader.desktop !=
                'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.desktop"
              >Desktop</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.ios != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.ios"
              >iOS</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.android !=
                'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.android"
              >Android</a
            >
            <a
              v-if="
                static_vars.platformLinks.actTrader.web != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.actTrader.web"
              >Web</a
            >
          </div>
          <div class="arsenal-holder">
            <h3>MetaTrader 4</h3>
            <p>Most Popular Platform</p>
            <div class="img-holder">
              <img src="assets/images/meta-img.webp" alt="AAAFx"
                title="MetaTrader 4" width="428" height="232" class="fluid-img" />
            </div>
            <a v-if="noDataMT4" href="javascript:void(0);">Coming Soon</a>
            <a
              v-if="
                static_vars.platformLinks.mt4.desktop != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt4.desktop"
              >Desktop</a
            >
            <a
              v-if="static_vars.platformLinks.mt4.ios != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt4.ios"
              >iOS</a
            >
            <a
              v-if="
                static_vars.platformLinks.mt4.android != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt4.android"
              >Android</a
            >
            <a
              v-if="static_vars.platformLinks.mt4.web != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt4.web"
              >Web</a
            >
          </div>
          <div class="arsenal-holder">
            <h3>MetaTrader 5</h3>
            <p>The next-gen platform</p>
            <div class="img-holder">
              <img src="assets/images/mt5-new.webp" class="fluid-img" alt="AAAFx"
                title="MetaTrader 5" width="440" height="238" />
            </div>
            <a v-if="noDataMT5" href="javascript:void(0);">Coming Soon</a>
            <a
              v-if="
                static_vars.platformLinks.mt5.desktop != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt5.desktop"
              >Desktop</a
            >
            <a
              v-if="static_vars.platformLinks.mt5.ios != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt5.ios"
              >iOS</a
            >
            <a
              v-if="
                static_vars.platformLinks.mt5.android != 'javascript:void(0);'
              "
              :href="static_vars.platformLinks.mt5.android"
              >Android</a
            >
            <a
              v-if="static_vars.platformLinks.mt5.web != 'javascript:void(0);'"
              :href="static_vars.platformLinks.mt5.web"
              >Web</a
            >
          </div>
          <!-- <div class="arsenal-holder">
            <h3>ZuluTrade</h3>
            <p>Auto-connects to the Social Trading Community</p>
            <div class="img-holder">
              <img src="assets/images/zulu-img.webp" alt="" />
            </div>
            <a href="#">Web</a>
            <a href="#">Mobile</a>
            <a href="#">Tablet</a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="section reg-steps">
      <div class="wrapper2">
        <div class="title-holder t-white wow fadeIn">
          <h3>Place Your First Trade with AAAFx in less than 15 minutes</h3>
        </div>
        <div class="listing-holder t-center">
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".2s">
            <div class="img-holder">
              <img src="assets/images/reg-ico1.webp" alt="AAAFx" title="AAAFx" width="48" height="44" class="fluid-img" />
            </div>
            <div class="content">
              <h3>Register</h3>
              <ul>
                <li>Within a min</li>
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".4s">
            <div class="img-holder">
              <img src="assets/images/reg-ico2.webp" alt="AAAFx" title="AAAFx" width="48" height="44" class="fluid-img" />
            </div>
            <div class="content">
              <h3>KYC</h3>
              <ul>
                <li>A Valid Photo ID</li>
                <li>A Recent Proof of Address</li>
                <li>Within 10 minutes</li>
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".6s">
            <div class="img-holder">
              <img src="assets/images/reg-ico3.webp" alt="AAAFx" title="AAAFx" width="48" height="44" class="fluid-img" />
            </div>
            <div class="content">
              <h3>Deposit</h3>
              <ul>
                <li>Instantly</li>
                <li>Over 5 deposit methods</li>
              </ul>
            </div>
          </div>
          <div class="steps-holder wow fadeInLeft" data-wow-delay=".8s">
            <div class="img-holder">
              <img src="assets/images/reg-ico4.webp" class="fluid-img" alt="AAAFx" title="AAAFx" width="48" height="44" />
            </div>
            <div class="content">
              <h3>Trade</h3>
              <ul>
                <li>A range of Markets</li>
                <li>Raw spreads</li>
                <li>Ultra-low-commission</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="btn-holder t-center wow fadeIn">
          <a :href="siteurl2" target="_blank" class="btn btn-green"
            >CREATE YOUR ACCOUNT</a
          >
        </div> 
      </div>
    </div>
    <div class="section payments-sec">
      <div class="wrapper2">
        <img src="assets/images/payment1.webp" alt="AAAFx" title="AAAFx" width="68" height="22" class="fluid-img" />
        <img src="assets/images/payment2.webp" alt="AAAFx" title="AAAFx" width="55" height="33" class="fluid-img" />
        <img src="assets/images/payment3.webp" alt="AAAFx" title="AAAFx" width="75" height="28" class="fluid-img" />
        <img src="assets/images/payment4.webp" alt="AAAFx" title="AAAFx" width="74" height="31" class="fluid-img" />
        <img src="assets/images/payment5.webp" alt="AAAFx" title="AAAFx" width="85" height="36" class="fluid-img" />
        <img src="assets/images/payment6.webp" alt="AAAFx" title="AAAFx" width="64" height="45" class="fluid-img" />
        <img src="assets/images/payment7.webp" alt="AAAFx" title="AAAFx" width="108" height="30" class="fluid-img" />
        <img src="assets/images/payment8.webp" alt="AAAFx" title="AAAFx" width="144" height="35" class="fluid-img" />
        <img src="assets/images/payment9.webp" alt="AAAFx" title="AAAFx" width="73" height="25" class="fluid-img" />
        <img src="assets/images/payment10.webp" alt="AAAFx" title="AAAFx" width="105" height="18" class="fluid-img" />
        <img src="assets/images/payment11.webp" alt="AAAFx" title="AAAFx" width="99" height="34" class="fluid-img" />
        <img src="assets/images/payment12.webp" alt="AAAFx" title="AAAFx" width="66" height="29" class="fluid-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
export default {
  name: "Home",
  data() {
    return {
      showTab: "forex",
      siteurl: process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2: process.env.VUE_APP_CLIENT_REGISTER_URL,
    };
  },
  methods: {},
  computed: {
    noDataMT4() {
      let flag = true,
        type = "mt4";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    noDataActTrader() {
      let flag = true,
        type = "actTrader";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    noDataMT5() {
      let flag = true,
        type = "mt5";
      console.log(type);
      for (let key in this.static_vars.platformLinks[type]) {
        if (
          this.static_vars.platformLinks[type][key] != "javascript:void(0);"
        ) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    get_currencies() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCurrencies();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_indices() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getIndicesAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Commodity() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCommodityAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Stocks() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getStocks();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
    get_Crypto() {
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive
        this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
        return this.$store.getters[Const.GET_PROVIDERS][
          "priceFeed"
        ].iCache.getCryptosAll();
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return [];
      }
    },
  },
};
</script>